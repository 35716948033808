'use strict';
require('sweetalert');
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$(document).ready(function(){
    $('.cont-slick').slick({
        infinite: true,
        adaptiveHeight: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: '.back',
        nextArrow: '.prev',
        draggable: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint : 449,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],

    });
});

var elementsForm,contLogin = 1;

function validationForm(elementsForm,contLogin)
{
    for(var i=0;i<elementsForm.length;i++){
        $('#'+elementsForm[i]).removeClass('error');
        if ( $.trim($("#"+elementsForm[i]).val()) == "" ) {
            contLogin = 0;
            $('#'+elementsForm[i]).addClass('error');
        }
    }
    return contLogin;
}
$('.btnFormContacto').click(function () {
    //$('#form').submit();
    grecaptcha.ready(function() {
        grecaptcha.execute('6LfRdtwZAAAAAGAsZTr769WArVkDxPg3hV2MFeU7', {action: 'submit'}).then(function(token) {
            $('#form').prepend('<input type="hidden" name="token" value="' + token + '">');
            $('#form').submit();
        });
    });
});
$('#form').on('submit',function (e) {
    e.preventDefault();
    elementsForm = [
        "nameContact",
        "cellphoneContact",
        "emailContact",
        "cityContact",
    ];
    contLogin = validationForm(elementsForm,contLogin = 1);
    if(contLogin == 0)
    {
        swal({
            text: "Debes digitar todos los datos requeridos",
            icon: imageURL,
            button: "OK",
        });
        return false;
    }
    var data = $(this).serialize();
    swal({
        text: "Espere un momento",
        icon: imageURL,
        button: false,
        closeOnClickOutside: false,
    });
    $.ajax({
        url: https + "contact",
        method: "POST",
        data: data,
        success: function (data) {
            if ($.trim(data) == "1") {
                $("#form")[0].reset();
                swal({
                    text: "Gracias por contactarnos",
                    icon: imageURL,
                    button: "OK",
                });
            }else {
                swal({
                    text: "Error, hubo problemas",
                    icon: imageURL,
                    button: "OK!",
                });
            }
        }
    });
});
